import React from 'react';
import { FormikPropsSlice } from 'primitives/input/formikUtils';
import { get } from 'lodash';
import { Omit } from 'utility-types';
import { ShSwitchBox, ShSwitchBoxProps } from '@shoootin/components-web';

export const SwitchBoxCloseAnimationTime = 200; // from css

// TODO remove when Migrating to components-web formik
export const SwitchBoxFormik = <FieldName extends string>({
  name,
  form,
  ...props
}: Omit<ShSwitchBoxProps, 'opened' | 'onOpenedChange'> & {
  name: FieldName;
  form: FormikPropsSlice<FieldName>;
}) => {
  const opened = get(form.values, name);
  const setOpened = (b: boolean) => form.setFieldValue(name, b);
  return <ShSwitchBox {...props} opened={opened} onOpenedChange={setOpened} />;
};
