import { GoogleMap, Marker, Polygon } from '@slorber/react-google-maps';
import React, { useMemo } from 'react';
import { AppInputSelectOptionList } from '../input/appInputSelect';

import { PlacePrediction } from './appMapHooks';
import {
  ShDefaultMarkerIcon,
  ShDefaultMapOptions,
  ShDefaultPolygonOptions,
} from '@shoootin/design-tokens';
import useId from '../../hooks/useId';

export const AppMap = ({
  onLoad,
  mapContainerStyle,
  zoom,
  center,
  onClick,
  options,
  children,
}: Pick<
  GoogleMap['props'],
  | 'onLoad'
  | 'mapContainerStyle'
  | 'zoom'
  | 'center'
  | 'onClick'
  | 'options'
  | 'children'
>) => {
  const finalOptions = useMemo(
    () => ({
      ...ShDefaultMapOptions,
      ...options,
    }),
    [options],
  );
  const finalMapContainerStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      ...mapContainerStyle,
    }),
    [mapContainerStyle],
  );

  const key = useId();
  return (
    <GoogleMap
      key={key}
      onLoad={onLoad}
      mapContainerStyle={finalMapContainerStyle}
      zoom={zoom}
      center={center}
      onClick={onClick}
      options={finalOptions}
    >
      {children}
    </GoogleMap>
  );
};

export const AppMapMarker = ({
  position,
}: Pick<Marker['props'], 'position'>) => {
  // Can't instantiace google maps point/anchor before maps lib has loaded...
  const finalIcon = useMemo(
    () => ({
      ...ShDefaultMarkerIcon,
      anchor: new google.maps.Point(16, 33),
    }),
    [],
  );

  return <Marker position={position} icon={finalIcon} />;
};

export const AppMapPolygon = ({
  paths,
  options,
  onClick,
}: Pick<Polygon['props'], 'paths' | 'options' | 'onClick'>) => {
  const finalOptions = useMemo(
    () => ({ ...ShDefaultPolygonOptions, ...options }),
    [options],
  );
  return <Polygon paths={paths} options={finalOptions} onClick={onClick} />;
};

export const AppPlacesDropdownOptions = ({
  places,
  onPlaceSelected,
}: {
  places: PlacePrediction[];
  onPlaceSelected: (place: PlacePrediction) => void;
}) => {
  return (
    <AppInputSelectOptionList
      options={places.map((place) => ({
        key: place.place_id,
        label: place.description,
      }))}
      onOptionSelected={(option) => {
        const place = places.find((p) => p.place_id === option.key);
        onPlaceSelected(place!);
      }}
    />
  );
};
