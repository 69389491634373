import React, { ReactNode } from 'react';
import '@emotion/core';
import { AppInputText, AppInputTextProps } from './appInputText';
import { AppInputSelectOptionList } from './appInputSelect';
import { SelectOption } from '../../appTypes';
import { Omit } from 'utility-types';
import { useFocus } from 'use-events';
import { mergeHandlers } from '../../utils/mergeHandlers';
import { AppDropdownPopper } from '../appPoppers';

export type AppInputTextAutocompleteDropdownProps = AppInputTextProps & {
  dropdownContent: ReactNode;
};
export const AppInputTextAutocompleteDropdown = ({
  dropdownContent,
  ...inputTextProps
}: AppInputTextAutocompleteDropdownProps) => {
  const [isFocused, bindFocus] = useFocus();
  const dropdownVisible = isFocused && !!dropdownContent;
  return (
    <AppDropdownPopper
      opened={dropdownVisible}
      target={(ref) => (
        <AppInputText
          {...inputTextProps}
          onFocus={mergeHandlers([bindFocus.onFocus, inputTextProps.onFocus])}
          onBlur={mergeHandlers([bindFocus.onBlur, inputTextProps.onBlur])}
          inputRef={ref}
        />
      )}
      content={() => dropdownContent}
    />
  );
};

export type AppInputTextAutocompleteProps<
  Key extends string | number = string
> = {
  options: SelectOption<Key>[];
  onSelect: (option: SelectOption<Key>) => void;
} & Omit<AppInputTextProps, 'onSelect'>;

export const AppInputTextAutocompleteSelect = <
  Key extends string | number = string
>({
  options,
  onSelect,
  ...inputTextProps
}: AppInputTextAutocompleteProps<Key>) => {
  return (
    <AppInputTextAutocompleteDropdown
      {...inputTextProps}
      dropdownContent={
        options.length > 0 ? (
          <AppInputSelectOptionList
            options={options}
            onOptionSelected={onSelect}
          />
        ) : undefined
      }
    />
  );
};
